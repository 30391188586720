import React from 'react'
import PricingItem from '../components/pricing-page/pricing-items'
import Layout from '../components/layout';
import SEO from '../components/seo'

const Pricing = ({location}) => {
    return (
        <Layout location={location}>
            <SEO title='Pricing' description='Blix product prices' />
            <PricingItem/>
        </Layout>
    );
}

export default Pricing;