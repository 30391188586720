import React from 'react';
import { Button, Typography } from '@material-ui/core';

export const data = [
    {
        title: 'Free',
        subtext1: 'For Business users',
        subtext2: <Typography gutterBottom style={{color: '#40BE91', fontSize: '26px', fontWeight: 'bold'}}>Free</Typography>,
        subtext3: 'Ad-free, No Hidden Fees',
        subtext4: 'Our full fledged app, including:',
        text: 'Email Accounts from any provider, all email protocols, Clusters, Aliases, Later Board, Unified folders, People Centric, Instant Push, Smart Notifications, Dynamic Conversations, Encryption and Security, Integrated Calendar, Dark Theme, Cross-Platform - Mobile & Desktop',
        btn: <Button variant="outlined" onClick={() => window.location.href="/download"}>Free Download</Button>,
        classprop: 'basic'
    },
    {
        title: 'Premium',
        subtext1: 'For small to medium teams',
        subtext2: <Typography gutterBottom style={{color: '#2179f3', fontSize: '47px', fontWeight: 'bold'}}>$4.99</Typography>,
        subtext3: 'Monthly or $49.99 annualy',
        subtext4: 'Everything in Free, plus:',
        text: 'Corporate Management Dashboard BYOD - EMM Support, Remote Device Management, Conditional Access, Custom Corporate Configurations, Priority Support',
        btn: <Button onClick={() => {window.location = "mailto:sales@blix.net"}} size="large" variant="outlined" color="primary">Get Premium</Button>,
        classprop: 'pro'
    },
    {
        title: 'Enterprise',
        subtext1: 'For large teams and projects',
        subtext2: <Typography gutterBottom style={{color: '#376ACC', fontSize: '26px', fontWeight: 'bold'}}>Get a Quote</Typography>,
        subtext3: 'Customised to your needs',
        subtext4: 'Everything in Premium, plus:',
        text: 'SSO & Provisioning Integration with multiple apps, Enterprise Edition, LDAP or Directory integration, 24/7 Priority Support',
        btn: <Button onClick={() => {window.location = "mailto:sales@blix.net"}} variant="outlined" color="primary">Contact Us</Button>,
        classprop: 'enterprise'
    },
]