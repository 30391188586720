import React from 'react'
import { ButtonGroup, Container, Grid, Typography, Card, CardHeader, CardContent, CardActions, Divider} from '@material-ui/core';
import {Link} from 'gatsby'
import { data } from '../../utils/pricing-data'

const PricingItem = () => {
    return (
       <Container className="pricing-page-container">
           <Typography align='center' className="title" >Do more with exclusive Blix features</Typography>
           <Typography align='center' style={{color: '#777777', fontSize: '23px', marginTop: '10px'}}>Start for <span style={{color: '#4571C0', fontWeight: 'bold'}}>Free</span>, or as low as $4.99 a month</Typography>


           <Grid container direction="row" justify="center" alignItems="center" spacing={4} style={{marginTop: '50px'}}>
               {data.map((data, i) => {
                return (
                <Grid className={data.classprop} key={i} item>
                    <Card className="card">
                        <CardHeader title={data.title} align='center' className="card-header"/>
                        <Typography gutterBottom style={{color: '#6F6F6F', marginTop: '20px'}}>{data.subtext1}</Typography>
                        {data.subtext2}
                        <Typography gutterBottom style={{color: '#A2A2A2', fontWeight: 'bold'}}>{data.subtext3}</Typography>
                        <Typography gutterBottom color='textPrimary'>{data.subtext4}</Typography>
                        <CardContent>
                            <Typography className='text'>{data.text}</Typography>
                        </CardContent>
                        <CardContent>
                            <Typography color='textPrimary'><Link to='/features' style={{textDecoration: 'none', color: '#434343'}}>Learn More</Link></Typography>
                        </CardContent>
                        <Divider variant="middle" />
                        <CardActions >
                            <ButtonGroup fullWidth aria-label="full width outlined button group">
                            {data.btn}
                            </ButtonGroup>
                        </CardActions>
                    </Card>
                
                </Grid>);
               })}
                
           </Grid>
       </Container>
    );
}

export default PricingItem